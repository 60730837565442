export const isWindow = () => !!window;

export const isMobileMedia = () => {
  const mediaQuery = isWindow() && window.matchMedia('(max-width: 599px)');
  if (mediaQuery && mediaQuery.matches) {
    return true;
  }
  return false;
};

export const debounce = (func: (args?: any) => void, timeout = 300) => {
  let timer: any;
  return (args?: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(args);
    }, timeout);
  };
};

export const loadLink = ({
  id,
  url,
  rel,
  type,
  className = '',
  dataProps
}: {
  id: string;
  url: string;
  rel?: string;
  type?: string;
  className?: string;
  dataProps?: Record<string, string>;
}) => {
  if (isWindow()) {
    const exists = document.getElementById(id);

    if (exists) {
      return;
    }

    const ele = document.createElement('link');
    ele.href = url;
    ele.id = id;

    if (rel) {
      ele.rel = rel;
    }
    if (type) {
      ele.type = type;
    }
    if (className) {
      ele.className = className;
    }

    if (dataProps) {
      Object.keys(dataProps).forEach((k) => ele.setAttribute(k, dataProps[k]));
    }

    document.head.appendChild(ele);
  }
};

export const loadScript = ({
  id,
  url,
  cb,
  async = true,
  defer = true,
  className = '',
  dataProps
}: {
  id: string;
  url: string;
  cb?: (args?: any) => void;
  async?: boolean;
  defer?: boolean;
  className?: string;
  dataProps?: Record<string, string>;
}) => {
  if (isWindow()) {
    const exists = document.getElementById(id);

    if (exists) {
      if (cb) {
        cb();
      }
      return;
    }

    const ele = document.createElement('script');
    ele.type = 'text/javascript';
    ele.src = url;
    ele.id = id;
    ele.async = async;
    ele.defer = defer;
    ele.className = className;

    if (dataProps) {
      Object.keys(dataProps).forEach((k) => ele.setAttribute(k, dataProps[k]));
    }

    ele.onload = () => {
      if (cb) {
        cb();
      }
    };

    document.body.appendChild(ele);
  }
};

export const isValidString = (val: any) => {
  return typeof val === 'string' && val.trim().length >= 1;
};

export const isValidEmail = (val: string) => {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/.test(val);
};

export const isValidUSFedTaxID = (val: string) => {
  return /^\d{2}-?\d{7}$/.test(val);
};

export const isValidBusinessNumber = (val: string) => {
  return /\d{9,}/.test(val);
};

export const isValidUSZip = (val: string) => {
  return /^\d{5}(-(\d){4})?$/.test(val);
};

export const isValidPostal = (val: string) => {
  return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(val);
};

export const isValidUSPhone = (val: string) => {
  return /\(\d{3}\) \d{3}-\d{4}/.test(val);
};

export const isValidNumber = (val: string | number) => {
  return typeof val === 'number' && !isNaN(val);
};

export const getAEMValue = (key: string, parsedModel: any, keyType: string, removeKeywords: Array<string> = []) => {
  if (!parsedModel) {
    return '';
  }
  const tags = ['<h1>', '</h1>', '<h2>', '</h2>', '<h3>', '</h3>', '<h4>', '</h4>', '<h5>', '</h5>', '<h6>', '</h6>'];
  if (!parsedModel[key]) {
    return '';
  }
  let value = parsedModel[key][keyType];
  if (value) {
    removeKeywords.forEach((keyword) => {
      value = value.replace(keyword, '');
    });
    tags.forEach((t) => {
      value = value.replace(t, '');
    });
    return value;
  }
  return '';
};

export const validateFileSize = (files: File[], maxTotalSize: number) => {
  if (!files.length) {
    return false;
  }
  let currTotalSize = 0;
  for (const file of files) {
    currTotalSize += file.size;
  }
  return currTotalSize <= maxTotalSize * 1024 * 1024;
};

export function getDateFromObj(obj: any) {
  if (obj && obj.day && obj.month && obj.year) {
    return `${obj.month}/${obj.day}/${obj.year}`;
  }
  return null;
}

export function checkFormObjectValidity(obj: Record<string, any>) {
  // If obj is null, return false
  if (obj === null) {
    return false;
  }

  // If obj is not an object, return false
  if (typeof obj !== 'object') {
    return true;
  }

  // If obj is an array, iterate through each element
  if (Array.isArray(obj)) {
    for (const item of obj) {
      // If the element is not valid, return false
      if (!checkFormObjectValidity(item)) {
        return false;
      }
    }
  }

  // Iterate through each key-value pair in obj
  for (const key in obj) {
    // If the value is null or not valid, return false
    if (obj[key] === null || !checkFormObjectValidity(obj[key])) {
      return false;
    }
  }

  // If all keys and nested keys are valid, return true
  return true;
}

export const autoFormat = (value: string, inputTypeFormat: string, region: string = 'us') => {
  try {
    if (inputTypeFormat === 'tel') {
      const phoneNumberString = value;
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{0,3})?(\d{0,3})?(\d{0,4})?/) || [];
      return [match[1] ? '(' : '', match[1], match[2] ? ') ' : '', match[2], match[3] ? '-' : '', match[3]].join('');
    }

    if (inputTypeFormat === 'zip') {
      const inputVal = value;
      const cleaned = ('' + inputVal).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{0,5})?(\d{0,4})?/) || [];
      return [match[1], match[2] ? '-' : '', match[2]].join('');
    }

    if (inputTypeFormat === 'currency') {
      const currenyString = value;
      const cleaned = ('' + currenyString).replace(/[^0-9.]/g, '');
      const match = cleaned.match(/^(\d{0,11})?(\.)?(\d{0,2})?/) || [];

      return [region === 'us' ? '$' : '$CAD', match[1].replace(/\B(?=(\d{3})+(?!\d))/g, ','), match[2], match[3]].join('');
    }

    if (inputTypeFormat === 'numeric') {
      const currenyString = value;
      const cleaned = ('' + currenyString).replace(/[^0-9.]/g, '');
      const match = cleaned.match(/^(\d{0,13})?(\.)?(\d{0,2})?/) || [];
      return [match[1].replace(/\B(?=(\d{3})+(?!\d))/g, ','), match[2], match[3]].join('');
    }

    if (inputTypeFormat === 'taxId') {
      const cleaned = ('' + value).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{0,2})?(\d{0,7})?/) || [];
      return [match[1], match[2] ? '-' : '', match[2]].join('');
    }

    if (inputTypeFormat === 'gstHstRegistration') {
      const cleaned = ('' + value).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{0,2})?(\d{0,7})?/) || [];
      return [match[1], match[2] ? '' : '', match[2]].join('');
    }

    return value;
  } catch (err) {
    return '';
  }
};

export const getCurrentDate = () => {
  const date = new Date();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  return `${mm}/${dd}/${date.getFullYear()}`;
};

export const setLocalStorage = (key: string, value: string) => {
  if (isWindow()) {
    localStorage.setItem(key, value);
  }
};

export const getLocalStorage = (key: string) => {
  if (isWindow()) {
    return localStorage.getItem(key);
  }
};

export const getSessionStorage = (key: string) => {
  if (isWindow()) {
    return sessionStorage.getItem(key);
  }
};

export const removeSymbols = (str: string): string => {
  if (typeof str !== 'string') {
    throw new Error('Input must be a string');
  }

  if (str.length === 0) {
    throw new Error('Input string is empty');
  }

  return str.replace(/[^0-9.]+/g, '');
};

export const toNumber = (str: string): number => {
  const num: number = Number(str);

  if (!isValidNumber(num)) {
    throw new Error('Invalid number format');
  }

  return num;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.substring(0, 3) + '-' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, 10);
};

export const getAEMPath = (key: string, path?: string) => {
  const paths: Record<string, string> = {
    businessInformation: process.env.AEM_BUSINESS_INFORMATION,
    contactInformation: process.env.AEM_CONTACT_INFORMATION,
    fleetInformation: process.env.AEM_FLEET_INFORMATION as string,
    billingInformation: process.env.AEM_BILLING_INFORMATION as string,
    cccdAgreement: process.env.AEM_CCCD_AGREEMENT as string,
    financialStatements: process.env.AEM_FINANCIAL_STATEMENTS,
    applicationReview: process.env.AEM_APPLICATION_REVIEW as string,
    signAndSubmit: process.env.AEM_SIGN_AND_SUBMIT,
    survey: process.env.AEM_SURVEY as string,
    screener: process.env.AEM_SCREENER as string,
    cloc: process.env.AEM_HOME_PAGE as string
  };

  if (path) {
    return Object.keys(paths).filter((k) => paths[k] === path)[0] || '';
  }

  return paths[key];
};

export const formatByteSize = (x: string) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1000 && ++l) {
    n = n / 1000;
  }

  return n.toFixed(n < 10 && l > 0 ? 2 : 1) + ' ' + units[l];
};

export const getFormSteps = (rg: string) => {
  /**
   * Form sequence with key names in global state form data
   */

  const usOnly = ['billingInformation', 'cccdAgreement'];

  const formSeqKeys = [
    'businessInformation',
    'contactInformation',
    'fleetInformation',
    'billingInformation',
    'cccdAgreement',
    'financialStatements',
    'applicationReview',
    'signAndSubmit'
  ];

  const formSeq = formSeqKeys.map((k) => ({ key: k, aemPath: getAEMPath(k) }));

  const steps = rg === 'ca' ? formSeq.filter((f) => !usOnly.includes(f.key)) : formSeq;

  const validationKeys = steps.slice(0, steps.length - 2).map((s) => s.key);

  return { steps, validationKeys };
};
