import { IDynamicObj } from '../../../store/types';
import { getAEMPath } from '../../../utils/jsUtils';
import CheckBox from '../../atoms/checkbox/checkbox';
import DatePicker from '../../atoms/datePicker';
import Input from '../../atoms/input';
import Select from '../../atoms/select';
import Text from '../../atoms/text';
import { customValidations } from './businessInfo';
import styles from './businessInfo.module.scss';

const AEM_PATH = getAEMPath('businessInformation');

interface BusinessInfoSectionProps {
  changeHandler: (o: IDynamicObj) => void;
  formInComplete: boolean;
  defaultValues: IDynamicObj;
  isBusinessNumberEnabled: boolean;
  region: string;
  stateList: { value: string; label: string }[];
}

const endYear = new Date().getFullYear();
const startYear = endYear - 100;

const BussinessInfoSection = ({
  changeHandler,
  formInComplete,
  defaultValues,
  isBusinessNumberEnabled,
  region,
  stateList
}: BusinessInfoSectionProps) => {
  const businessNumberCheckHandler = ({ value }: IDynamicObj) => {
    const foCheckBox = (value as Record<string, string | boolean>[])[0].checked;
    if (foCheckBox) {
      const newVal = { name: 'gstHstRegistration', value: '' };
      changeHandler(newVal);
    }
    const newVal = { name: 'noBusinessNumber', value: foCheckBox };
    changeHandler(newVal);
  };
  return (
    <div className={styles.section}>
      <Text name="bi" />
      <div className={styles.form}>
        <Input
          type="text"
          name="businessName"
          changeHandler={changeHandler}
          required
          validate={formInComplete}
          defaultValue={defaultValues.businessName as string}
          showBlankSpaceValidation
          dataTestId="businessLegalNameInput"
        />
        <Text
          name="businessName-desc"
          className={styles.desc}
          bodySize="s"
          bodyWeight="m"
          bodyColor="subtle"
        />
        {region === 'us' ? (
          <Input
            type="text"
            name="federalTaxId"
            inputTypeFormat="taxId"
            changeHandler={changeHandler}
            customValidation={customValidations.federalTaxId}
            validate={formInComplete}
            defaultValue={defaultValues.federalTaxId as string}
            required
            dataTestId="taxIdInput"
            customErrorKeys={['invalid']}
          />
        ) : (
          <>
            <Input
              type="text"
              name="gstHstRegistration"
              changeHandler={changeHandler}
              customValidation={customValidations.businessNumber}
              validate={!isBusinessNumberEnabled && formInComplete}
              defaultValue={defaultValues.gstHstRegistration as string}
              dataTestId="gstInput"
              disabled={isBusinessNumberEnabled}
              required
              customErrorKeys={['invalid']}
              inputTypeFormat="gstHstRegistration"
            />
            <Text
              name="gstHstRegistration-desc"
              className={styles.desc}
              bodySize="s"
              bodyWeight="m"
              bodyColor="subtle"
            />
            <CheckBox
              name="noBusinessNumber"
              className={styles.topSpacing}
              changeHandler={businessNumberCheckHandler}
              defaultValue={{ 0: isBusinessNumberEnabled }}
              validate={formInComplete}
              pagePath={AEM_PATH}
            />
            <DatePicker
              name="incorporationDate"
              validate={formInComplete}
              value={defaultValues.incorporationDate as object | string}
              changeHandler={changeHandler}
              selectorStartingYear={startYear}
              selectorEndingYear={endYear}
            />
          </>
        )}
        <Input
          type="text"
          name="doingBusinessAs"
          changeHandler={changeHandler}
          validate={formInComplete}
          defaultValue={defaultValues.doingBusinessAs as string}
          dataTestId="dbaInput"
        />

        {region === 'us' ? (
          <Select
            name="organizationState"
            options={stateList}
            changeHandler={changeHandler}
            defaultValue={defaultValues.organizationState}
            validate={formInComplete}
            pagePath={AEM_PATH}
            required
          />
        ) : (
          <Select
            name="incorporationProvince"
            options={stateList}
            changeHandler={changeHandler}
            defaultValue={defaultValues.incorporationProvince}
            validate={formInComplete}
            pagePath={AEM_PATH}
            required
          />
        )}
        <Input
          type="text"
          name="website"
          changeHandler={changeHandler}
          validate={formInComplete}
          defaultValue={defaultValues.website as string}
          dataTestId="websiteInput"
        />
      </div>
    </div>
  );
};

export default BussinessInfoSection;
